import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig, ValidateEmail, normalize } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { addNewSubAgent } from "./../../../config/api_calls";

import $ from "jquery";

import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";

const auth = new HelperClass();

class Addagent extends Component {
  constructor() {
    super();
    this.state = {
      serviceData: [],
      select_service: "",
      errorMessage: "",
      serviceId: "",
      agent_title: "",
      agent_name: "",
      agent_logo: "",
      agent_email: "",
      agent_phone: "",
      agent_password: "",
      c_password: "",
      description: "",
      successMessage: "",
      tableLoader: true,
      domain: "",
      step: 1,
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {}

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  formAgentUpdate = async () => {
    let {
      agent_title,
      agent_name,
      agent_email,
      agent_password,
      c_password,
      agent_phone,
    } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
    });
    let is_validated = true;
    if (this.state.agent_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Agent Name is required.",
      });
      is_validated = false;
    } else if (this.state.agent_title === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Title is required.",
      });
      is_validated = false;
    } else if (this.state.agent_phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (normalize(this.state.agent_phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(this.state.agent_email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (this.state.agent_password !== "") {
      if (this.state.agent_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (this.state.c_password === "") {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (this.state.c_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (
        this.state.agent_password.trim() != this.state.c_password.trim()
      ) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      let apiResponce = await addNewSubAgent(
        auth.getAccount(),
        auth.getToken(),
        agent_title,
        agent_name,
        agent_email,
        agent_password,
        agent_phone
      );
      console.log("Sub Agent Result:", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessage: "Sub agent added successfully.",
          tableLoader: false,
        });
        setTimeout(async function () {
          window.location.replace("/agents");
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "Account Already Exists"
      ) {
        this.setState({
          errorMessage:
            "Email already exists. Please try different email address.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessage: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessage: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/agents")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Agents
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add New Sub Agent
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div class="card-head">
                    <h5 class="card-title">Enter sub agent details below.</h5>
                  </div>
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <form method="post" encType="multipart/form-data">
                    <div className="row g-4">
                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="agent_name"
                          name="agent_name"
                          type="text"
                          label="Agent Name"
                          inputProps={{ maxLength: 100 }}
                          defaultValue={this.state.agent_name}
                          onChange={this.handleChange}
                          helperText="Enter the sub agent name."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="agent_title"
                          name="agent_title"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          label="Agent Title"
                          defaultValue={this.state.agent_title}
                          onChange={this.handleChange}
                          helperText="Enter the title here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="agent_email"
                          name="agent_email"
                          type="email"
                          label="Email Address"
                          defaultValue={this.state.agent_email}
                          onChange={this.handleChange}
                          helperText="Enter your email address here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="agent_phone"
                          name="agent_phone"
                          type="number"
                          label="Phone Number"
                          inputProps={{ maxLength: 11 }}
                          defaultValue={this.state.agent_phone}
                          onChange={this.handleChange}
                          helperText="Enter the phone number here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="agent_password"
                          name="agent_password"
                          type="password"
                          label="Password"
                          defaultValue={this.state.agent_password}
                          inputProps={{ maxLength: 30 }}
                          onChange={this.handleChange}
                          helperText="Enter your password here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="c_password"
                          name="c_password"
                          type="password"
                          label="Confirm Password"
                          defaultValue={this.state.c_password}
                          inputProps={{ maxLength: 30 }}
                          onChange={this.handleChange}
                          helperText="Enter the confirm password here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div style={{ clear: "both" }}></div>
                      <hr />
                      <div className="form-group text-right col-md-12">
                        <button
                          className="btn btn-lg btn-primary"
                          type="button"
                          disabled={this.state.disabled}
                          onClick={this.formAgentUpdate}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  {/*<StepWizard>
                    <Step1
                      handleChange={this.handleChangeStep}
                      values={values}
                    />
                    <Step2
                      handleChange={this.handleChangeStep}
                      values={values}
                    />
                  </StepWizard>*/}

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addagent);
