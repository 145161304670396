const initialState = {
  settings: "",
  menuDetails: [],
  is_msa_signed: "No",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return { ...state, settings: action.payload };
    case "SETMENU":
      return { ...state, menuDetails: action.payload };
    case "SETMSAINFO":
      return { ...state, is_msa_signed: action.payload };
    default:
      return state;
  }
};
export default reducer;
