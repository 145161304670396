import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import { singleSignOn } from "../../../config/client_api_calls";

export default class SsoAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
    };
  }

  async componentDidMount() {
    let reseller_url = this.props.match.params.reseller_url;
    let token_code = this.props.match.params.token_code;
    let client_id = this.props.match.params.client_id;
    console.log(
      "reseller_url: " +
        reseller_url +
        " \nclient_id: " +
        client_id +
        " \n\ntoken_code: " +
        token_code
    );
    if (reseller_url === "" && token_code === "") {
      this.setState({
        errorMessage: "URL is not valid. Please enter the valid URL.",
      });
    } else {
      let apiHit = await singleSignOn(token_code, reseller_url, client_id);
      console.log("apiHit.data: ", apiHit.data);
      if (
        apiHit.data.status === 404 &&
        apiHit.data.message === "Invalid or Expired Token."
      ) {
        //window.location.replace("/error");
        this.setState({
          errorMessage: "Invalid credentials or expired token.",
          successMessage: "",
        });
      } else if (
        apiHit.data.status === 200 &&
        apiHit.data.message === "success"
      ) {
        //Remove Previous Session
        Cookies.remove("comp_nme__");
        Cookies.remove("eml__");
        Cookies.remove("acn__");
        Cookies.remove("tkn__");
        Cookies.remove("residentifier__");
        Cookies.remove("restitle__");
        Cookies.remove("ressig__");
        Cookies.remove("restkn__");
        Cookies.remove("resacct__");

        Cookies.remove("client_name__");
        Cookies.remove("client_comp_nme__");
        Cookies.remove("client_eml__");
        Cookies.remove("client_acn__");
        Cookies.remove("client_tkn__");
        Cookies.remove("client_phone__");
        Cookies.remove("client_title__");


        Cookies.set(
          "client_name__",
          CryptoJS.AES.encrypt(
            apiHit.data.name,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_tkn__",
          CryptoJS.AES.encrypt(
            apiHit.data.token,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_acn__",
          CryptoJS.AES.encrypt(
            apiHit.data.accountno,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_eml__",
          CryptoJS.AES.encrypt(
            apiHit.data.email,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_comp_nme__",
          CryptoJS.AES.encrypt(
            apiHit.data.comp_name,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_title__",
          CryptoJS.AES.encrypt(
            apiHit.data.title,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "client_phone__",
          CryptoJS.AES.encrypt(
            apiHit.data.phone,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "residentifier__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_domain_identifier,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "reslogo__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_logo,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "restkn__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_hash,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "resacct__",
          CryptoJS.AES.encrypt(
            apiHit.data.reseller_accountno,
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );

        
        this.setState({
          errorMessage: "",
          successMessage: "Account authenticated successfully.",
        });
        setTimeout(function () {
          window.location.replace(
            "/" + reseller_url + "/client/dashboard"
          );
        }, 3000);
      } else {
        this.setState({
          errorMessage:
            "Your account cannot be authenticated this time. Please check the URL or try again later.",
          successMessage: "",
        });
      }
    }
  }

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {this.state.errorMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.successMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-success">
                    <div className="alert-text">
                      <h4>Success</h4>
                      <p>{this.state.successMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="text-center" style={{ marginTop: "20%" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
