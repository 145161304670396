import { React } from "react";
import { Component } from "react";
import { axiosConfig, ValidateEmail, normalize } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listSubClients,
  resendActivation,
  updateSubClient,
  listSubClientRights,
  addSubCLientRights,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class SubClients extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      errorMessageEdit: "",
      successMessageEdit: "",
      errorMessageRights: "",
      successMessageRights: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      client_accountno: "",
      subclient_accountno: "",
      client_name: "",
      client_email: "",
      client_phone: "",
      client_status: "",
      client_password: "",
      client_c_password: "",
      allRights: [],
      assignedMainRights: [],
      assignedSubRights: [],
      columnServices: [
        { name: "Name" },
        { name: "Company" },
        {
          name: "Phone",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatUsPhoneNumber(value)}</div>;
            },
          },
        },
        { name: "Email" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Service Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Sub Client</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.client_rights(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Sub client rights"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-unlock"></em>
                                  <span>Client Rights</span>
                                </a>
                              </li>
                              {tableMeta.rowData[5] === "inactive" ? (
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.resendActivation(value, tableMeta)
                                    }
                                    title="Resend Activation"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em className="icon ni ni-signin"></em>

                                    <span>Resend Activation</span>
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[5];
    //console.log("domain_str: ", domain_str);
    if (domain_str === "active") {
      return (
        <div>
          <span className="badge badge-outline-secondary">
            {" "}
            <em className="icon ni ni-done" title="Active"></em> Active
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Inactive
          </span>
        </div>
      );
    }
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];
    if (is_default === "No") {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Make Default
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  defaultMarketing = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value.split("###");
    let marketing_content = is_default[0];
    let marketing_link = is_default[1];
    if (marketing_content === "link") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Product Info
          </span>
        </a>
      );
    } else if (marketing_content === "pdf") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em> View PDF
          </span>
        </a>
      );
    } else {
      return "";
    }
  };

  async componentDidMount() {
    const servicesResponce = await listSubClients(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    //console.log("Available Sub Clients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  /*formatPhoneNumber = (value, tableMeta) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };*/

  resendActivation = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[6];
    this.setState({
      activation_button: true,
      activation_tick: false,
      activation_cross: false,
    });
    window.$("#modalAlertResend").modal("show");
    const kycResponce = await resendActivation(
      auth.getClientAccount(),
      auth.getClientToken(),
      client_accountno
    );
    //console.log("resendActivation: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_found"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: true,
        activation_cross: false,
      });
    } else {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
    }
    setTimeout(() => {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("hide");
    }, 4000);
  };

  showDetails = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[6];
    let client_name = tableMeta.rowData[0];
    let client_email = tableMeta.rowData[3];
    let client_phone = tableMeta.rowData[2];
    let client_status = tableMeta.rowData[5];

    this.setState({
      client_accountno: client_accountno,
      client_name: client_name,
      client_email: client_email,
      client_phone: client_phone,
      client_status: client_status,
    });

    window.$("#modalFormDetail").modal("show");
  };

  client_rights = async (value, tableMeta) => {
    console.log("client_rights: ", value);
    this.setState({
      tableLoaderListing: true,
    });
    window.$("#modalFormRights").modal("show");
    const servicesResponce = await listSubClientRights(
      auth.getClientAccount(),
      auth.getClientToken(),
      value
    );
    console.log("Client Rights Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoaderListing: false,
        errorMessageRights: "There is some error while getting the rights.",
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        subclient_accountno: value,
        allRights: servicesResponce.data.allRights,
        assignedMainRights: servicesResponce.data.assignedMainRights,
        assignedSubRights: servicesResponce.data.assignedSubRights,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoaderListing: false,
        errorMessageRights: "There is some error while getting the rights.",
      });
      //window.location.replace("/error");
    }
  };

  formSaveRights = async () => {
    let { client_accountno, client_parent, subclient_accountno } = this.state;
    var sub_selected_menus = [];
    var main_selected_menus = $("input:checkbox.vdrSelected:checked")
      .map(function () {
        sub_selected_menus = $(
          "input:checkbox.vdrSelectedSub" + this.value + ":checked"
        )
          .map(function () {
            return this.value;
          })
          .get();
        console.log("sub_selected_menus: ", sub_selected_menus);
        let insideArray = {
          main_id: this.value,
          subclient_accountno: subclient_accountno,
          submenus: sub_selected_menus,
        };
        return insideArray;
      })
      .get();

    console.log("main_selected_services: ", main_selected_menus);

    const servicesResponce = await addSubCLientRights(
      auth.getClientAccount(),
      auth.getClientToken(),
      subclient_accountno,
      JSON.stringify(main_selected_menus)
    );
    console.log("Add Rights Result: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "client_not_found"
    ) {
      //window.location.replace("/error");
      this.setState({
        successMessageRights: "",
        disabled: false,
        errorMessageRights: "Sub client not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        successMessageRights: "Rights added successfully.",
        errorMessageRights: "",
        disabled: false,
      });
    } else {
      this.setState({
        successMessageRights: "",
        disabled: false,
        errorMessageRights: "There is some error while adding the rights.",
      });
    }
    setTimeout(() => {
      this.setState({
        successMessageRights: "",
        disabled: false,
        errorMessageRights: "",
      });
    }, 4000);
  };

  formUpdateSubmit = async () => {
    let {
      client_title,
      client_name,
      client_email,
      client_password,
      client_c_password,
      client_phone,
      client_status,
      client_accountno,
    } = this.state;
    this.setState({
      successMessageEdit: "",
      errorMessageEdit: "",
    });
    let is_validated = true;
    if (client_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Client Name is required.",
      });
      is_validated = false;
    } else if (client_phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (normalize(client_phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (client_password !== "") {
      if (client_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (client_c_password === "") {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (client_c_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (client_password.trim() != client_c_password.trim()) {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      let apiResponce = await updateSubClient(
        auth.getClientAccount(),
        auth.getClientToken(),
        client_name,
        client_password,
        client_phone,
        client_status,
        client_accountno
      );
      //console.log("Update Sub Client Result:", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        this.setState({
          successMessageEdit: "Sub client updated successfully.",
          tableLoader: false,
        });
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "already_exists"
      ) {
        this.setState({
          errorMessageEdit:
            "Email already exists. Please try different email address.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }

    setTimeout(() => {
      this.setState({
        errorMessageEdit: "",
        successMessageEdit: "",
      });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(
      "/" + auth.getResellerIdentifier() + "/" + link
    );
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="SubClients_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Sub CLients" icon="icon ni ni-users" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            {this.props.defaultSettings !== "missing" ? (
                              <a
                                onClick={() =>
                                  this.addMenuHistory(
                                    "client/SubClients/AddSubClient"
                                  )
                                }
                                style={{
                                  color: "#fff",
                                }}
                                className="btn btn-primary"
                              >
                                <em className="icon ni ni-plus-round"></em>
                                <span>Add New Sub Client</span>
                              </a>
                            ) : null}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {/* START DATATABLE */}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title="All Sub Clients"
                            />
                          )}

                          {/* END TABLE */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              <div class="modal fade" tabindex="-1" id="modalAlertResend">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <a href="#" class="close" data-dismiss="modal">
                      <em class="icon ni ni-cross"></em>
                    </a>
                    <div class="modal-body modal-body-lg text-center">
                      <div class="nk-modal">
                        {this.state.activation_button === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                            <h4 class="nk-modal-title">Sending!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                Please wait while we are processing your request
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_tick === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 class="nk-modal-title">Congratulations!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                Activation email has been sent successfully.
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                        {this.state.activation_cross === true ? (
                          <>
                            <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 class="nk-modal-title">Oops!</h4>
                            <div class="nk-modal-text">
                              <div class="caption-text">
                                There is some error while sending activation
                                email. Please try again later
                              </div>
                              <span class="sub-text-sm"></span>
                            </div>
                            <div class="nk-modal-action"></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div class="modal-footer bg-lighter">
                      <div class="text-center w-100">
                        <a
                          href="#"
                          class="btn btn-lg btn-mw btn-primary"
                          data-dismiss="modal"
                        >
                          Close
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ACTIVATION MODAL END */}
              {/* EDIT MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-edit"></em> Edit Sub Client
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <form method="post" encType="multipart/form-data">
                          <div className="row g-4">
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="client_name"
                                name="client_name"
                                type="text"
                                label="Sub Client Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.client_name}
                                onChange={this.handleChange}
                                helperText="Enter the sub client name."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="client_email"
                                name="client_email"
                                type="email"
                                label="Email Address"
                                value={this.state.client_email}
                                onChange={this.handleChange}
                                helperText="Please note that the email address provided is read-only."
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                }}
                                readOnly
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="client_phone"
                                name="client_phone"
                                type="number"
                                label="Phone Number"
                                inputProps={{ maxLength: 11 }}
                                value={this.state.client_phone}
                                onChange={this.handleChange}
                                helperText="Enter the phone number here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                name="client_status"
                                select
                                label="Status"
                                value={this.state.client_status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText=""
                                variant="outlined"
                                fullWidth
                              >
                                <option key="st1" value="active">
                                  Active
                                </option>
                                <option key="st2" value="inactive">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="client_password"
                                name="client_password"
                                type="password"
                                label="Password"
                                value={this.state.client_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter your password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="client_c_password"
                                name="client_c_password"
                                type="password"
                                label="Confirm Password"
                                value={this.state.client_c_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter the confirm password here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group text-right col-md-12">
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.formUpdateSubmit}
                              >
                                Submit
                              </button>
                            </div>
                            <div className="example-alert border-top">
                              <div className="alert alert-light alert-icon">
                                <em className="icon ni ni-alert-circle"></em>{" "}
                                <ul className="list">
                                  <li>
                                    Please note that the email address provided
                                    is read-only. It cannot be modified or
                                    changed. If you need to update your email
                                    address, please contact our support team for
                                    assistance.
                                  </li>
                                  <li>
                                    To ensure the security of your account,
                                    please ensure that the password and confirm
                                    password fields match. The values entered in
                                    both fields must be identical for successful
                                    submission.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* EDIT MODAL End */}
              {/* RIGHTS MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormRights">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-unlock"></em> Sub Client
                        Rights
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageRights !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageRights}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageRights !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageRights}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className=" g-4">
                        {this.state.tableLoaderListing === true ? (
                          tableLoaderListing()
                        ) : (
                          <>
                            <div id="no-more-tables-new">
                              <p>
                                These permissions allow users to access and view
                                information, data, or content within the system.
                              </p>
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-os">
                                      <span class="overline-title">
                                        Main Menu
                                      </span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Sub Menu
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.allRights &&
                                  this.state.allRights.length > 0 ? (
                                    <>
                                      {this.state.allRights.map(
                                        (mainRights, i) => (
                                          <tr key={`menu${i}`}>
                                            <td class="tb-col-os">
                                              <div className="custom-control custom-control custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id={`cp-main-menu${i}`}
                                                  name="main_menu_id"
                                                  defaultChecked={
                                                    this.state
                                                      .assignedMainRights &&
                                                    this.state
                                                      .assignedMainRights
                                                      .length > 0
                                                      ? this.state.assignedMainRights.includes(
                                                          mainRights.link_id
                                                        )
                                                      : null
                                                  }
                                                  value={mainRights.link_id}
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={`cp-main-menu${i}`}
                                                >
                                                  {mainRights.alt}
                                                </label>
                                              </div>
                                            </td>
                                            <td class="tb-col-ip">
                                              {mainRights.submenu &&
                                              mainRights.submenu.length > 0 ? (
                                                <>
                                                  {mainRights.submenu.map(
                                                    (subRights, index) => (
                                                      <div
                                                        className="col-md-6 col-lg-6 mb-2 inner_column"
                                                        key={`submenu${index}`}
                                                      >
                                                        <div className="custom-control custom-control custom-checkbox align-switch-div">
                                                          <input
                                                            type="checkbox"
                                                            className={`custom-control-input vdrSelectedSub${mainRights.link_id}`}
                                                            id={`cp-sub-menu${index}`}
                                                            name="sub_menu_id"
                                                            defaultChecked={
                                                              this.state
                                                                .assignedSubRights &&
                                                              this.state
                                                                .assignedSubRights
                                                                .length > 0
                                                                ? this.state.assignedSubRights.includes(
                                                                    subRights.link_id
                                                                  )
                                                                : null
                                                            }
                                                            value={
                                                              subRights.link_id
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            required=""
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor={`cp-sub-menu${index}`}
                                                          >
                                                            {subRights.alt}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </>
                                              ) : null}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                            <div className="nk-kycfm-action m-3 text-right">
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                                onClick={this.formSaveRights}
                              >
                                <em className="icon ni ni-plus-round"></em>
                                Save Rights
                              </button>
                            </div>
                          </>
                        )}
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* RIGHTS MODAL End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubClients);
