import React, { Component } from "react";

import styles from "../../../components/Client/styles.module.css";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import $ from "jquery";
import PROMO_A from "../../../assets/images/error-404.svg";
import {
  agreementVerification,
  saveVerificationAgreement,
  saveChooseSignatureSignee,
} from "../../../config/api_calls";
import { REPLACECONTENTCLIENT } from "../../../config/utility";
import {
  THEME_COLOR,
  THEME_TEXT_COLOR,
  VENDOR_SIGNATURE,
  ADMIN_URL,
} from "../../../config/config";
import "../../../components/Client/agreement.css";
import HelperClass from "../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import { tableLoaderAgreement } from "../../../components/ContentLoaders/table_loader_agreement";

import html2canvas from "html2canvas";

const auth = new HelperClass();

export default class AgreementsVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      tableLoaderAgreement: true,
      errorMessageSignee: "",
      successMessageSignee: "",
      agreementData: [],
      replacable_content: "",
      final_content: "",
      agreement_id: "",
      agreement_accountno: "",
      agreement_title: "",
      signature_zero: 1,
      signature_one: 0,
      signature_two: 0,
      signature_three: 0,
      signature_four: 0,
      vendor_signature: "",
      agreement_type: "esign",
      is_checked: "",
      signatureText: "",
      signatureClass: "'Amita', cursive",
      signature_type: "draw",
      client_token: "",
      agent_accountno: "",
      reseller_identifier: "",
    };
    this.cursiveFonts = [
      { name: "Your Signature", value: "'Amita', cursive" },
      { name: "Your Signature", value: "'Bad Script', cursive" },
      { name: "Your Signature", value: "'Damion', cursive" },
      { name: "Your Signature", value: "'Dancing Script', cursive" },
      { name: "Your Signature", value: "'Felipa', cursive" },
      { name: "Your Signature", value: "'Julee', cursive" },
      { name: "Your Signature", value: "'Kaushan Script', cursive" },
      { name: "Your Signature", value: "'Lugrasimo', cursive" },
      { name: "Your Signature", value: "'Pacifico', cursive" },
      { name: "Your Signature", value: "'Sofia', cursive" },
      { name: "Your Signature", value: "'Yellowtail', cursive" },
      // Add more cursive fonts here
    ];
  }

  async componentDidMount() {
    console.log(
      "this.props.match.params.verification_token: ",
      this.props.match.params.verification_token
    );
    let apiHit = await agreementVerification(
      this.props.match.params.verification_token
    );
    console.log("apiHit.data: ", apiHit.data);
    if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "Invalid Credientials"
    ) {
      //window.location.replace("/error");
      this.setState({
        errorMessage: "Invalid Credentials.",
        successMessage: "",
        tableLoaderAgreement: false,
      });
    } else if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "no_record"
    ) {
      this.setState({
        errorMessage:
          "Oops! Your temporary URL has been expired or does not exists.",
        successMessage: "",
        tableLoaderAgreement: false,
      });
    } else if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "already_signed"
    ) {
      this.setState({
        errorMessage: "Oops! Agreement already signed.",
        successMessage: "",
        tableLoaderAgreement: false,
      });
    } else if (apiHit.data.status === 200) {
      let objReplace = {
        resellerCompName: apiHit.data.reseller_data[0].comp_name,
        client_name: apiHit.data.user_data[0]["name"],
        client_comp_name: apiHit.data.user_data[0]["comp_name"],
        client_email: apiHit.data.user_data[0]["email"],
        client_address: apiHit.data.user_data[0]["address"]
          ? apiHit.data.user_data[0]["address"]
          : "N/A",
        client_city: apiHit.data.user_data[0]["city"]
          ? apiHit.data.user_data[0]["city"]
          : "N/A",
        client_state: apiHit.data.user_data[0]["state"]
          ? apiHit.data.user_data[0]["state"]
          : "N/A",
        client_zip: apiHit.data.user_data[0]["zip"]
          ? apiHit.data.user_data[0]["zip"]
          : "N/A",
        client_phone_no: apiHit.data.user_data[0]["phone"],
        vendor_signor_name: apiHit.data.reseller_data[0].name,
        vendor_signor_comp_name: apiHit.data.reseller_data[0].comp_name,
        vendor_signor_title: apiHit.data.reseller_data[0].title,
        vendor_signor_address: apiHit.data.reseller_data[0].address,
        vendor_signor_email: "N/A",
        vendor_signor_phone_no: apiHit.data.reseller_data[0].phone,
        vendor_signor_fax: "N/A",
        vendor_signor_city: apiHit.data.reseller_data[0].city,
        vendor_signor_state: apiHit.data.reseller_data[0].state,
        vendor_signor_zip: apiHit.data.reseller_data[0].zip,
        vendor_signor_comp_title: apiHit.data.reseller_data[0].comp_name,
      };
      let final_content = apiHit.data.data[0][1];
      let signature =
        apiHit.data.reseller_data[0].signature_image &&
        apiHit.data.reseller_data[0].signature_image !== ""
          ? ADMIN_URL +
            "files_data/logos/" +
            apiHit.data.reseller_data[0].signature_image
          : VENDOR_SIGNATURE;
      final_content = REPLACECONTENTCLIENT(
        final_content,
        objReplace,
        ""
      ).replace(/\[\[IMG_VENDOR_SIGN\]\]/g, signature);
      this.setState({
        replacable_content: objReplace,
        agreementData: apiHit.data.data,
        errorMessage: "",
        final_content: final_content,
        bgColor: "white",
        defaultLoader: false,
        agreement_id: apiHit.data.data[0][0],
        agreement_type: apiHit.data.data[0][8],
        agreement_title: apiHit.data.data[0][9],
        agreement_accountno: apiHit.data.data[0][6],
        vendor_signature: signature,
        tableLoaderAgreement: false,
        client_token: apiHit.data.token,
        agent_accountno: apiHit.data.user_data[0]["accountno"],
        reseller_identifier: apiHit.data.reseller_data[0].reseller_identifier,
      });
    } else {
      this.setState({
        errorMessage:
          "Oops! There is some error while processing your request.",
        successMessage: "",
        tableLoaderAgreement: false,
      });
    }
  }

  clear = () => {
    this.signaturePad.clear();
    $("#divImgSig").hide();
    $(".clsimgSig").attr("src", "");
    $(".clsimgSig").hide();
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    this.props.agreementSigned();
  };

  save = async () => {
    console.log("agreement_id: " + this.state.agreement_id);

    if (
      this.state.agreement_type === "accept" &&
      this.state.is_checked !== "yes"
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must accept the agreement.",
      });
    } else if (
      this.state.agreement_type === "esign" &&
      this.signaturePad.isEmpty() &&
      this.state.signature_type === "draw"
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must sign the mandatory agreements.",
      });
    } else if (
      this.state.agreement_type === "esign" &&
      this.state.signature_type === "choose" &&
      this.state.signatureText == ""
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage: "Please enter the authorized person name.",
      });
    } else {
      $("#loading_btn").show();
      $("#save_btn").hide();

      this.setState({
        errorMessage: "",
      });
      if (this.state.signature_type === "draw") {
        var data = "";
        if (this.state.agreement_type === "esign") {
          data = this.signaturePad
            .getTrimmedCanvas()
            .toDataURL("image/svg+xml");
        }

        const apiHit = await saveVerificationAgreement(
          this.state.agent_accountno,
          this.state.client_token,
          data,
          this.state.final_content.replace(
            "CLIENT SIGNATURE DATE SHOWN HERE",
            this.props.published_date
          ),
          this.state.agreement_id,
          this.state.agreement_accountno,
          this.state.vendor_signature,
          this.state.agreement_type
        );
        console.log("MSA: ", apiHit.data);
        if (apiHit.data.status === 404) {
          window.$("#modalAlertError").modal("show");
          this.setState({
            errorMessage: "We are sorry, something went wrong try later.",
          });
        } else if (
          apiHit.data.status === 200 &&
          apiHit.data.data === "success"
        ) {
          $("#loading_btn").hide();
          $("#save_btn").show();
          this.setState({
            successMessage: "Agreement has been signed successfully.",
            agreementData: [],
          });
        }

        $("#divSig").show();
        $("#divImgSig").show();
        $(".clsimgSig").show();
        $(".clsimgSig").attr("src", data);
      } else if (this.state.signature_type === "choose") {
        this.submitSignatoryDetails();
      }
    }
  };

  submitSignatoryDetails = async () => {
    let { signatureText, signature_type } = this.state;
    this.setState({
      verifyDetailsButton: true,
    });
    let is_validated = true;
    if (signatureText === "") {
      is_validated = false;
    }
    if (signature_type === "choose") {
      await this.saveAsImage();
    }
    if (signature_type === "draw") {
      //await this.saveSignature();
    }
  };

  saveAsImage = async () => {
    let { signatureText, signature_type } = this.state;
    const element = document.getElementById("styled-text-container");
    console.log("element: ", element);
    if (element) {
      html2canvas(element, { scale: 2, backgroundColor: null }).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");

          const apiData = await saveChooseSignatureSignee(
            this.state.agent_accountno,
            this.state.client_token,
            imgData,
            signatureText,
            signature_type,
            this.state.final_content.replace(
              "VENDOR SIGNATURE DATE SHOWN HERE",
              this.props.published_date
            ),
            this.state.agreement_id,
            VENDOR_SIGNATURE,
            this.state.agreement_type
          );
          console.log("saveSignature: ", apiData.data);
          if (
            apiData.data.status === 403 ||
            apiData.data.errors === "authentication missing" ||
            apiData.data.errors === "jwt expired"
          ) {
            //auth.clientLogout();
          } else if (apiData.data.status === 404) {
            this.setState({
              errorMessage:
                "There is some error while processing your request.",
              successMessage: "",
              verifyDetailsButton: false,
              disabled: false,
            });
          } else if (
            apiData.data.status === 200 &&
            apiData.data.data === "success"
          ) {
            this.setState({
              successMessage: "Agreement has been signed successfully.",
              agreementData: [],
            });
          }
        }
      );
    }
  };

  set_data = (agreement_content) => {
    this.setState({
      final_content: agreement_content,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.value : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  updateSignatureType = (type) => {
    this.setState({
      signature_type: type,
    });
  };

  handleSignature = (family) => {
    this.setState({ signatureClass: family });
  };

  preview = () => {
    if (!this.signaturePad.isEmpty()) {
      var data = this.signaturePad
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");
      $("#divSig").show();
      $("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  class_manage_link = (ids) => {
    if (ids === 0) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  };

  class_manage_content = (ids) => {
    if (ids === 0) {
      return "tab-pane active";
    } else {
      return "tab-pane";
    }
  };

  class_required = (ids) => {
    if (ids === 1) {
      return (
        <sup style={{ fontSize: "100%" }}>
          <small className="" style={{ color: "#e85347", fontSize: "100%" }}>
            *
          </small>
        </sup>
      );
    } else {
      return "";
    }
  };

  set_tabs = (ids) => {
    if (ids !== "") {
      return "#agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  set_tabs_content = (ids) => {
    if (ids !== "") {
      return "agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  show_agreement_content = async (
    ids,
    agreement_content,
    agreement_type,
    agreement_title
  ) => {
    console.log("replacable_content: ", this.state.replacable_content);
    let final_content = agreement_content;
    if (final_content !== "") {
      final_content = REPLACECONTENTCLIENT(
        agreement_content,
        this.state.replacable_content,
        ""
      ).replace(/\[\[IMG_VENDOR_SIGN\]\]/g, this.state.vendor_signature);
    }
    this.setState({
      final_content: final_content,
      agreement_id: ids,
      agreement_type: agreement_type,
      agreement_title: agreement_title,
    });
  };

  close_window = () => {
    window.close();
  };

  render() {
    return (
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    {this.state.agreementData.length > 0 ? (
                      <h3 className="nk-block-title page-title">
                        <em className="icon ni ni-list-round"></em> Agreements
                      </h3>
                    ) : null}
                  </div>
                </div>
              </div>
              {this.state.errorMessage !== "" ? (
                <div className="nk-wrap justify-center">
                  <div className="nk-content ">
                    <div className="nk-block nk-block-middle wide-md mx-auto">
                      <div className="nk-block-content nk-error-ld text-center">
                        <img className="nk-error-gfx" src={PROMO_A} alt="" />
                        <div className="wide-xs mx-auto">
                          <h3 className="nk-error-title">
                            {this.state.errorMessage}
                          </h3>
                          <p className="nk-error-text">
                            We are very sorry for inconvenience. It looks like
                            youa are try to access a page that either has been
                            deleted or never existed.
                          </p>
                          {/*<a
                            href="https://voyceadmins.com/contact"
                            className="btn btn-primary mt-2"
                          >
                            Contact Us
              </a>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.successMessage !== "" ? (
                <>
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successMessage}</p>
                      </div>
                    </div>
                  </div>
                  <center>
                    <a
                      onClick={this.close_window}
                      className="btn btn-primary mt-2"
                      style={{ color: "#fff" }}
                    >
                      Close Window
                    </a>
                  </center>
                </>
              ) : null}
              {this.state.tableLoaderAgreement === true ? (
                tableLoaderAgreement()
              ) : (
                <div className="nk-block">
                  <div className="row g-gs">
                    {/* Table 5th Col Start */}
                    <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="nk-block nk-block-lg">
                        <div
                          className="card card-bordered"
                          style={{
                            backgroundColor: this.props.backgroundColor,
                            border: "none",
                          }}
                        >
                          {this.state.agreementData.length > 0 ? (
                            <div className="card-inner card-inner-xl">
                              <ul className="nav nav-tabs">
                                {this.state.agreementData.map(
                                  (agreements, i) => (
                                    <li
                                      className="nav-item"
                                      key={`agrtitle${i}`}
                                    >
                                      <a
                                        className={this.class_manage_link(i)}
                                        data-toggle="tab"
                                        href={this.set_tabs(agreements[6])}
                                        onClick={() =>
                                          this.show_agreement_content(
                                            agreements[0],
                                            agreements[1],
                                            agreements[8],
                                            agreements[2]
                                          )
                                        }
                                      >
                                        <em className="icon ni ni-list-round"></em>
                                        <span>
                                          {agreements[9]}{" "}
                                          {this.class_required(agreements[3])}
                                        </span>
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                              <div className="tab-content">
                                {this.state.agreementData.map(
                                  (agreementscontent, i) => (
                                    <div
                                      className={this.class_manage_content(i)}
                                      id={this.set_tabs_content(
                                        agreementscontent[6]
                                      )}
                                      key={`agrcont${i}`}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: REPLACECONTENTCLIENT(
                                            agreementscontent[1],
                                            this.state.replacable_content,
                                            ""
                                          ).replace(
                                            /\[\[IMG_VENDOR_SIGN\]\]/g,
                                            this.state.vendor_signature
                                          ),
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                                {/* ACTION BUTTONS DIV  START*/}
                                {this.state.agreement_type === "esign" ? (
                                  <>
                                    <div className="row g-gs d-flex justify-content-end">
                                      <div className="form-group col-md-6">
                                        <ul className="nav nav-tabs">
                                          <li className="nav-item">
                                            <a
                                              className={
                                                this.state.signature_type ===
                                                  "draw" ||
                                                this.state.signature_type === ""
                                                  ? "nav-link active"
                                                  : "nav-link"
                                              }
                                              data-toggle="tab"
                                              onClick={() =>
                                                this.updateSignatureType("draw")
                                              }
                                              href="#tabItem6"
                                            >
                                              <em className="icon ni ni-edit-alt"></em>
                                              <span>Draw</span>
                                            </a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className={
                                                this.state.signature_type ===
                                                "choose"
                                                  ? "nav-link active"
                                                  : "nav-link"
                                              }
                                              data-toggle="tab"
                                              onClick={() =>
                                                this.updateSignatureType(
                                                  "choose"
                                                )
                                              }
                                              href="#tabItem5"
                                            >
                                              <em className="icon ni ni-list-check"></em>
                                              <span>Choose</span>
                                            </a>
                                          </li>
                                        </ul>
                                        <div className="tab-content">
                                          <div
                                            className={
                                              this.state.signature_type ===
                                              "choose"
                                                ? "tab-pane active"
                                                : "tab-pane"
                                            }
                                            id="tabItem5"
                                          >
                                            <div class="example-alert">
                                              <div class="alert alert-light">
                                                Pick a style for your signature
                                                (Authorized Person Name).
                                              </div>
                                            </div>
                                            <div className="form-group mt-4">
                                              <div className="form-group">
                                                <MutextField
                                                  required
                                                  id="signatureText"
                                                  name="signatureText"
                                                  type="text"
                                                  label="Authorized Person Name"
                                                  inputProps={{ maxLength: 30 }}
                                                  onInput={(e) => {
                                                    e.target.value =
                                                      e.target.value.replace(
                                                        /[^a-zA-Z0-9 ]/gi,
                                                        ""
                                                      );
                                                  }}
                                                  defaultValue={
                                                    this.state.signatureText
                                                  }
                                                  onChange={this.handleChange}
                                                  helperText=""
                                                  variant="outlined"
                                                  fullWidth
                                                />
                                              </div>
                                              <h6 className="title">
                                                Choose Style
                                              </h6>

                                              <ul className="cc-pay-method">
                                                <li className="cc-pay-dd dropdown">
                                                  <a
                                                    href="#"
                                                    className="btn btn-white btn-outline-light dropdown-toggle dropdown-indicator"
                                                    data-toggle="dropdown"
                                                  >
                                                    <span
                                                      style={{
                                                        fontFamily:
                                                          this.state
                                                            .signatureClass,
                                                      }}
                                                    >
                                                      {this.state
                                                        .signatureText ||
                                                        "Your Signature"}
                                                    </span>
                                                  </a>
                                                  <div className="dropdown-menu dropdown-menu-auto">
                                                    <ul className="link-list-plain">
                                                      {this.cursiveFonts.map(
                                                        (font, index) => (
                                                          <li key={index}>
                                                            <a
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() =>
                                                                this.handleSignature(
                                                                  font.value
                                                                )
                                                              }
                                                              className="cc-pay-item"
                                                            >
                                                              <span className="cc-pay-item-name">
                                                                <span
                                                                  className="cc-pay-item-method"
                                                                  style={{
                                                                    fontFamily:
                                                                      font.value,
                                                                  }}
                                                                >
                                                                  {this.state
                                                                    .signatureText !==
                                                                  ""
                                                                    ? this.state
                                                                        .signatureText
                                                                    : font.name}
                                                                </span>
                                                              </span>
                                                            </a>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                            <div
                                              className="signature-preview mt-3"
                                              id="styled-text-container"
                                              style={{
                                                fontFamily:
                                                  this.state.signatureClass,
                                                fontSize: "40px",
                                                width: "480px",
                                                height: "80px",
                                                background: "trasnparent",
                                                backgroundColor: "transparent",
                                                border: "none",
                                                lineHeight: "80px",
                                                color: "#000",
                                              }}
                                            >
                                              {this.state.signatureText ||
                                                "Your Signature"}
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              this.state.signature_type ===
                                                "draw" ||
                                              this.state.signature_type === ""
                                                ? "tab-pane active"
                                                : "tab-pane"
                                            }
                                            id="tabItem6"
                                          >
                                            <div
                                              className="form-group col-md-12 "
                                              id="signature_div_inner"
                                            >
                                              <div className="row g-gs">
                                                <div className="col-md-12 ">
                                                  <div class="example-alert mb-4">
                                                    <div class="alert alert-light">
                                                      Please Save the signature
                                                      to proceed.
                                                    </div>
                                                  </div>
                                                  <h6>Signature</h6>
                                                  <div
                                                    className="form-group"
                                                    style={{ height: "120px" }}
                                                  >
                                                    <SignatureCanvas
                                                      clearOnResize={true}
                                                      canvasProps={{
                                                        className:
                                                          styles.sigPad,
                                                      }}
                                                      velocityFilterWeight={0.7}
                                                      throttle={40}
                                                      ref={(ref) => {
                                                        this.signaturePad = ref;
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <h6></h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="signature_div">
                                      {/*<Row>
                                      <Col sm={12}>
                                        <div>
                                          <strong>Sign Here:</strong>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "1%" }}>
                                      <Col sm={12}>
                                        <SignatureCanvas
                                          clearOnResize={true}
                                          canvasProps={{
                                            height: "400px",
                                            className: styles.sigPad,
                                          }}
                                          velocityFilterWeight={0.7}
                                          throttle={50}
                                          ref={(ref) => {
                                            this.signaturePad = ref;
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: "1%" }}>
                                      <Col sm={12}>
                                        <div style={{ float: "right" }}>
                                          <button
                                            className="btn btn-lg btn-warning"
                                            style={{
                                              margin: "13px 12px 12px 10px",
                                            }}
                                            onClick={() => this.clear()}
                                          >
                                            Clear
                                          </button>
                                          <button
                                            className="btn btn-lg btn-success"
                                            style={{
                                              margin: "13px 12px 12px 10px",
                                            }}
                                            onClick={() => this.preview()}
                                          >
                                            Preview Signature
                                          </button>
                                          <button
                                            className="btn btn-lg"
                                            type="button"
                                            id="loading_btn"
                                            style={{
                                              display: "none",
                                              backgroundColor: THEME_COLOR,
                                              color: THEME_TEXT_COLOR,
                                            }}
                                            disabled
                                          >
                                            <span
                                              className="spinner-grow spinner-grow-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                            <span> Wait... </span>
                                          </button>
                                          <button
                                            className="btn btn-lg"
                                            type="button"
                                            id="loading_btn"
                                            style={{
                                              display: "none",
                                              backgroundColor: THEME_COLOR,
                                              color: THEME_TEXT_COLOR,
                                            }}
                                            disabled
                                          >
                                            <span
                                              className="spinner-grow spinner-grow-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                            <span> Wait... </span>
                                          </button>
                                          <button
                                            className="btn btn-lg"
                                            style={{
                                              backgroundColor: THEME_COLOR,
                                              color: THEME_TEXT_COLOR,
                                              margin: "13px 12px 12px 10px",
                                            }}
                                            onClick={() => this.save()}
                                            id="save_btn"
                                          >
                                            Save Agreement
                                          </button>
                                        </div>
                                      </Col>
                                          </Row>*/}
                                    </div>
                                  </>
                                ) : null}
                                {this.state.agreement_type === "accept" ? (
                                  <div
                                    id="signature_div"
                                    className="signature_width"
                                  >
                                    <div className="nk-block">
                                      <div className="row g-gs">
                                        <div className="col-md-12">
                                          <div className="card card-bordered card-full">
                                            <div className="nk-wg1">
                                              <div className="nk-wg1-block">
                                                <div className="nk-wg1-text">
                                                  <div className="form-group">
                                                    <div className="custom-control custom-control-xs custom-checkbox">
                                                      <input
                                                        type="checkbox"
                                                        name="is_checked"
                                                        className="custom-control-input"
                                                        id="checkbox"
                                                        defaultChecked={
                                                          this.state.is_checked
                                                        }
                                                        value="yes"
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                      <label
                                                        className="custom-control-label"
                                                        htmlFor="checkbox"
                                                      >
                                                        I accept the{" "}
                                                        {
                                                          this.state
                                                            .agreement_title
                                                        }
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="nk-wg1-action"
                                                style={{ padding: "1.25rem" }}
                                              >
                                                <div className="form-group ">
                                                  <button
                                                    className="btn btn-lg"
                                                    type="button"
                                                    id="loading_btn"
                                                    style={{
                                                      display: "none",
                                                      backgroundColor:
                                                        THEME_COLOR,
                                                      color: THEME_TEXT_COLOR,
                                                    }}
                                                    disabled
                                                  >
                                                    <span
                                                      className="spinner-grow spinner-grow-sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                    ></span>
                                                    <span> Wait... </span>
                                                  </button>
                                                  <button
                                                    className="btn btn-lg"
                                                    type="button"
                                                    id="loading_btn"
                                                    style={{
                                                      display: "none",
                                                      backgroundColor:
                                                        THEME_COLOR,
                                                      color: THEME_TEXT_COLOR,
                                                    }}
                                                    disabled
                                                  >
                                                    <span
                                                      className="spinner-grow spinner-grow-sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                    ></span>
                                                    <span> Wait... </span>
                                                  </button>

                                                  <button
                                                    className="btn btn-primary"
                                                    onClick={() => this.save()}
                                                    id="save_btn"
                                                  >
                                                    Accept and Continue
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.agreement_type === "esign" ? (
                                  <div className="row g-gs d-flex justify-content-end mt-4">
                                    <div
                                      className=""
                                      style={{ float: "right" }}
                                    >
                                      {this.state.signature_type === "draw" ? (
                                        <>
                                          <button
                                            className="btn btn-lg btn-warning"
                                            style={{
                                              margin: "13px 12px 12px 10px",
                                            }}
                                            onClick={() => this.clear()}
                                          >
                                            Clear
                                          </button>
                                          <button
                                            className="btn btn-lg btn-success"
                                            style={{
                                              margin: "13px 12px 12px 10px",
                                            }}
                                            onClick={() => this.preview()}
                                          >
                                            Preview Signature
                                          </button>
                                        </>
                                      ) : null}
                                      <button
                                        className="btn btn-lg"
                                        type="button"
                                        id="loading_btn"
                                        style={{
                                          display: "none",
                                          backgroundColor: THEME_COLOR,
                                          color: THEME_TEXT_COLOR,
                                        }}
                                        disabled
                                      >
                                        <span
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span> Wait... </span>
                                      </button>
                                      <button
                                        className="btn btn-lg"
                                        type="button"
                                        id="loading_btn"
                                        style={{
                                          display: "none",
                                          backgroundColor: THEME_COLOR,
                                          color: THEME_TEXT_COLOR,
                                        }}
                                        disabled
                                      >
                                        <span
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span> Wait... </span>
                                      </button>
                                      <button
                                        className="btn btn-lg"
                                        style={{
                                          backgroundColor: THEME_COLOR,
                                          color: THEME_TEXT_COLOR,
                                          margin: "13px 12px 12px 10px",
                                        }}
                                        onClick={() => this.save()}
                                        id="save_btn"
                                      >
                                        Save Agreement
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                                {/* ACTIONS BUTTONS DIV END */}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* Table 5th Col End */}
                  </div>
                </div>
              )}

              {/* SUCCESS MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalAlert">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHide();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4 className="nk-modal-title">
                          {this.state.successMessage}
                        </h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            You’ve successfully signed{" "}
                            <strong>Agreement</strong>
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.modalHide();
                            }}
                          >
                            OK
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SUCCESS MODAL End */}
              {/* ERROR MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalAlertError">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                        <h4 className="nk-modal-title">Unable to Process!</h4>
                        <div className="nk-modal-text">
                          <p className="lead">{this.state.errorMessage}</p>
                        </div>
                        <div className="nk-modal-action mt-5">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-light"
                            data-dismiss="modal"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ERROR MODAL END */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
