import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listDashboard,
  listTerminationSessions,
  getSixMonthGrowth,
} from "./../../../config/api_calls";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import {
  LineChart,
  ComposedChart,
  Area,
  Bar,
  Line,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceDot,
  Brush,
  Scatter,
} from "recharts";
const auth = new HelperClass();
var md5 = require("md5");

class Analytics extends Component {
  constructor() {
    super();
    this.state = {
      customer_origination_sessions: [],
      customer_termination_sessions: [],
      six_month_growth: [],
      origination_names: [],
      termination_names: [],
      tableLoader: true,
      errorMessage: "",
    };
  }

  async componentDidMount() {
    this.getOriginationSessions();
    this.getTerminationSessions();
    this.getGrowth();
    //setInterval(this.getOriginationSessions(), 60000);
    //setInterval(this.getTerminationSessions(), 60000);
  }

  async getOriginationSessions() {
    const servicesResponce = await listDashboard(
      auth.getClientAccount(),
      auth.getClientToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      return this.setState({
        errorMessage: "There is some error while populating the graph.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        customer_origination_sessions: servicesResponce.data.result,
        origination_names: servicesResponce.data.origination_names,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
      return this.setState({
        errorMessage: "There is some error while populating the graph.",
      });
    }
  }

  async getTerminationSessions() {
    const servicesResponce = await listTerminationSessions(
      auth.getClientAccount(),
      auth.getClientToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      return this.setState({
        errorMessage: "There is some error while populating the graph.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        customer_termination_sessions: servicesResponce.data.result,
        termination_names: servicesResponce.data.termination_names,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
      return this.setState({
        errorMessage: "There is some error while populating the graph.",
      });
    }
  }

  async getGrowth() {
    const servicesResponce = await getSixMonthGrowth(
      auth.getClientAccount(),
      auth.getClientToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      return this.setState({
        errorMessage: "There is some error while populating the graph.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        six_month_growth: servicesResponce.data.result,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
      return this.setState({
        errorMessage: "There is some error while populating the graph.",
      });
    }
  }

  defaultColor = (value) => {
    let color = "#" + md5(value).slice(0, 6);
    return color;
  };

  render() {
    return (
      <div className="nk-content " id="Analytics_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <PageTitle name="Analytics" icon="icon ni ni-growth" />

                    <div className="nk-block-des text-soft">
                      <p>System wise analytics.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-gs">
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 24 Hour
                          Origination Customer Sessions
                        </h6>
                      </div>
                      <div
                        className=""
                        style={{ width: "100%", height: "300px" }}
                      >
                        <ResponsiveContainer>
                          <LineChart
                            data={this.state.customer_origination_sessions}
                            margin={{ top: 5, bottom: 5 }}
                            padding={{ left: 3, right: 3 }}
                          >
                            <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                            <YAxis tick={{ fontSize: 11 }} />
                            <Tooltip />
                            <CartesianGrid
                              stroke="#f5f5f5"
                              strokeDasharray="1 1"
                            />

                            {this.state.origination_names.map((services) => (
                              <Line
                                type="monotone"
                                dot={false}
                                dataKey={services}
                                stroke={this.defaultColor(services)}
                              />
                            ))}
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 24 Hour
                          Termination Customer Sessions
                        </h6>
                      </div>
                      <div
                        className=""
                        style={{ width: "100%", height: "300px" }}
                      >
                        <ResponsiveContainer>
                          <LineChart
                            data={this.state.customer_termination_sessions}
                            margin={{ top: 5, bottom: 5 }}
                          >
                            <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                            <YAxis tick={{ fontSize: 11 }} />
                            <Tooltip />
                            <CartesianGrid
                              stroke="#f5f5f5"
                              strokeDasharray="1 1"
                            />

                            {this.state.termination_names.map((services) => (
                              <Line
                                type="monotone"
                                dot={false}
                                dataKey={services}
                                stroke={this.defaultColor(services)}
                              />
                            ))}
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-gs">
                <div className="col-md-6">
                  <div className="card card-bordered card-preview">
                    <div className="card-inner">
                      <div className="card-head">
                        <h6 className="title">
                          <em className="icon ni ni-line-chart-up"></em> 6 Month
                          Growth
                        </h6>
                      </div>
                      <div
                        className=""
                        style={{ width: "100%", height: "300px" }}
                      >
                        <ResponsiveContainer>
                          <ComposedChart
                            data={this.state.six_month_growth}
                            margin={{
                              top: 20,
                              right: 20,
                              bottom: 20,
                              left: 20,
                            }}
                          >
                            <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                            <YAxis
                              orientation="right"
                              yAxisId="1"
                              tickFormatter={(tick) => `${tick}%`}
                              domain={[0, 100]}
                              tick={{ fontSize: 11 }}
                            />
                            <YAxis orientation="left" yAxisId="2" />
                            <Legend />
                            <CartesianGrid stroke="#f5f5f5" />
                            <Tooltip />

                            <Bar
                              dataKey="New Signups"
                              barSize={20}
                              fill="#28c619"
                              yAxisId="2"
                            />
                            <Line
                              type="monotone"
                              yAxisId="1"
                              dataKey="Growth"
                              stroke="#ff7300"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
