export function addSettings(payload) {
  return {
    type: "SET_SETTINGS",
    payload,
  };
}

export function addMsaInfo(payload) {
  return {
    type: "SETMSAINFO",
    payload,
  };
}

export function setHeader(payload) {
  return {
    type: "SETHEADER",
    payload,
  };
}

export function addSettingsAdmin(payload) {
  return {
    type: "SET_SETTINGS",
    payload,
  };
}
