import React from "react";
import ContentLoader from "react-content-loader";

export const DataList = (props) => {
  const newWidth = window.innerWidth * 0.8;
  return (
    <ContentLoader
      width={newWidth}
      height={304}
      primaryColor="#e4e4e4"
      secondaryColor="#d3d3d3"
      {...props}
    >
      <rect x="0" y="13" rx="2" ry="2" width="123" height="33" />
      <rect x="0" y="63" rx="0" ry="0" width={newWidth} height="4" />
      <rect x="0" y="83" rx="0" ry="0" width={newWidth} height="4" />
      <rect x="0" y="103" rx="0" ry="0" width={newWidth} height="4" />
      <rect x="0" y="123" rx="0" ry="0" width={newWidth} height="4" />
      <rect x="0" y="143" rx="0" ry="0" width={newWidth} height="4" />
      <rect x="0" y="163" rx="0" ry="0" width={newWidth} height="4" />
    </ContentLoader>
  );
};
