import { React } from "react";
import { Component } from "react";
import styles from "./../../../styles.module.css";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { connect } from "react-redux";
import { addSettings } from "../../../actions";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import {
  unsignedAgreements,
  saveGeneralAgreement,
  sendToSignee,
} from "./../../../config/client_api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderBlockImage } from "../../../components/ContentLoaders/table_loader_imageblock";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import { REPLACECONTENT, ValidateEmail } from "./../../../config/utility";
import MutextField from "@material-ui/core/TextField";
const auth = new HelperClass();

class Agreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
      tableLoaderBlockImage: true,
      pdfurl: "",
      unsigned_agreements: "1",
      replacable_content: "",
      final_content: "",
      agreement_id: "",
      vendor_signature: "",
      agreementData: [],
      errorMessageSignee: "",
      successMessageSignee: "",
      agreement_type: "esign",
      is_checked: "",
      signee_name: "",
      signee_email: "",
      signee_title: "",
      agreement_service_type: "",
      agreement_accountno: "",
      agreement_title: "",
    };
  }

  viewDownloadBtns = (tableMeta) => {
    return (
      <div>
        <a
          onClick={() =>
            this.addMenuHistory(
              "/agreements/pdfdownload/" +
                btoa(tableMeta.rowData[0] + "###" + tableMeta.rowData[4])
            )
          }
          disabled={tableMeta.rowData[2] === "No" ? true : false}
          target="_blank"
          style={{ cursor: "pointer", color: "#006393" }}
        >
          <GetAppOutlinedIcon
            style={{
              cursor: "pointer",
            }}
          />
        </a>{" "}
      </div>
    );
  };

  async componentDidMount() {
    let apiData = await unsignedAgreements(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("UnSignedAgreement DATA: ", apiData.data);
    this.setState({
      tableLoaderBlockImage: true,
    });
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (apiData.data.status === 404 || apiData.data.data.length === 0) {
      console.log("First 404 Error");
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
        tableLoaderBlockImage: false,
      });
      //this.props.history.push("/error");
    } else if (apiData.data.status === 200 && apiData.data.data.length > 0) {
      this.setState({});
      let objReplace = {
        resellerCompName: auth.getCompName(),
        reseller_name: apiData.data.user_data[0]["name"],
        reseller_comp_name: apiData.data.user_data[0]["comp_name"],
        reseller_email: apiData.data.user_data[0]["email"],
        reseller_address: apiData.data.user_data[0]["address"]
          ? apiData.data.user_data[0]["address"]
          : "N/A",
        reseller_city: apiData.data.user_data[0]["city"]
          ? apiData.data.user_data[0]["city"]
          : "N/A",
        reseller_state: apiData.data.user_data[0]["state"]
          ? apiData.data.user_data[0]["state"]
          : "N/A",
        reseller_zip: apiData.data.user_data[0]["zip"]
          ? apiData.data.user_data[0]["zip"]
          : "N/A",
        reseller_phone_no: apiData.data.user_data[0]["phone"],
        vendor_signor_name: apiData.data.reseller_data[0].reseller_name,
        vendor_signor_comp_name: apiData.data.reseller_data[0].reseller_comp,
        vendor_signor_title: apiData.data.reseller_data[0].reseller_title,
        vendor_signor_address: apiData.data.reseller_data[0].reseller_address,
        vendor_signor_email: "N/A",
        vendor_signor_phone_no: apiData.data.reseller_data[0].reseller_phone,
        vendor_signor_fax: "N/A",
        vendor_signor_city: apiData.data.reseller_data[0].reseller_city,
        vendor_signor_state: apiData.data.reseller_data[0].reseller_state,
        vendor_signor_zip: apiData.data.reseller_data[0].reseller_zip,
        vendor_signor_comp_title: apiData.data.reseller_data[0].reseller_comp,
      };

      /*newContent = newContent.replace(
        "VENDOR SIGNATURE DATE SHOWN HERE",
        apiData.data.agreement[0]["published_date"]
      );*/
      let final_content = apiData.data.data[0][1];
      final_content = REPLACECONTENT(final_content, objReplace, "").replace(
        /\[\[IMG_VENDOR_SIGN\]\]/g,
        this.state.vendor_signature
      );
      this.setState({
        replacable_content: objReplace,
        agreementData: apiData.data.data,
        errorMessage: "",
        final_content: final_content,
        bgColor: "white",
        defaultLoader: false,
        agreement_id: apiData.data.data[0][0],
        agreement_type: apiData.data.data[0][8],
        vendor_signature: apiData.data.reseller_data[0].reseller_signature,
        agreement_accountno: apiData.data.data[0][6],
        agreement_title: apiData.data.data[0][9],
        agreement_service_type: apiData.data.data[0][10],
        tableLoaderBlockImage: false,
      });
      //this.show_signature(0);
    } else if (apiData.data.status === 406) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        agreementData: [],
        tableLoaderBlockImage: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
        tableLoaderBlockImage: false,
      });
      console.log("Last Error");
      //this.props.history.push("/error");
    }
  }

  open_signee = async () => {
    console.log("agreement_id: " + this.state.agreement_id);
    this.setState({
      errorMessageSignee: "",
      successMessageSignee: "",
    });
    window.$("#modalSendSignee").modal("show");
  };

  close_signee = async () => {
    window.$("#modalSendSignee").modal("hide");
  };

  send_to_signee = async () => {
    console.log("agreement_id: " + this.state.agreement_id);
    this.setState({
      successMessageSignee: "",
      errorMessageSignee: "",
      disabled: true,
    });
    const {
      agreement_id,
      signee_name,
      signee_email,
      signee_title,
      agreement_service_type,
    } = this.state;

    var validated = true;
    if (signee_name === "") {
      validated = false;
    } else if (signee_email === "") {
      validated = false;
    } else if (ValidateEmail(signee_email) === false) {
      validated = false;
      this.setState({
        errorEmail: "error",
        errorMessageSignee: "Invalid email format.",
      });
    }

    if (validated === false) {
      this.setState({
        errorMessageSignee: "Please enter the required fields.",
        disabled: false,
      });
    } else {
      const apiData = await sendToSignee(
        auth.getClientAccount(),
        auth.getClientToken(),
        agreement_id,
        signee_name,
        signee_email,
        signee_title,
        agreement_service_type
      );
      console.log("sendToSignee: ", apiData.data);
      if (
        apiData.data.status === 403 ||
        apiData.data.errors === "authentication missing" ||
        apiData.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (apiData.data.status === 404) {
        this.setState({
          errorMessageSignee: "We are sorry, something went wrong try later.",
          disabled: false,
        });
        // this.props.mainHistory.history.push("/error");
        // console.log("errors: ", apiData.data);
      } else if (
        apiData.data.status === 200 &&
        apiData.data.message === "success"
      ) {
        this.setState({
          successMessageSignee:
            "Agreement has been sent successfully to the signee.",
          disabled: false,
          signee_name: "",
          signee_email: "",
          signee_title: "",
        });
      }
    }
  };

  save = async () => {
    console.log("agreement_id: " + this.state.agreement_id);

    if (
      this.state.agreement_type === "accept" &&
      this.state.is_checked !== "yes"
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must accept the agreement.",
      });
    } else if (
      this.state.agreement_type === "esign" &&
      this.signaturePad.isEmpty()
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must sign the mandatory agreements.",
      });
    } else {
      $("#loading_btn").show();
      $("#save_btn").hide();

      this.setState({
        errorMessage: "",
      });
      var data = "";
      if (this.state.agreement_type === "esign") {
        data = this.signaturePad.getTrimmedCanvas().toDataURL("image/svg+xml");
      }

      const apiData = await saveGeneralAgreement(
        auth.getClientAccount(),
        auth.getClientToken(),
        data,
        this.state.final_content.replace(
          "CLIENT SIGNATURE DATE SHOWN HERE",
          this.props.published_date
        ),
        this.state.agreement_id,
        this.state.agreement_accountno,
        this.state.vendor_signature,
        this.state.agreement_type
      );
      console.log("MSA: ", apiData.data);
      if (
        apiData.data.status === 403 ||
        apiData.data.errors === "authentication missing" ||
        apiData.data.errors === "jwt expired"
      ) {
        auth.clientLogout();
      } else if (apiData.data.status === 404) {
        window.$("#modalAlertError").modal("show");
        return this.setState({
          errorMessage: "We are sorry, something went wrong try later.",
        });
        // this.props.mainHistory.history.push("/error");
        // console.log("errors: ", apiData.data);
      } else if (
        apiData.data.status === 200 &&
        apiData.data.data === "success"
      ) {
        $("#loading_btn").hide();
        $("#save_btn").show();

        window.location.replace(
          "/" + auth.getResellerIdentifier() + "/client/dashboard"
        );
      }

      $("#divSig").show();
      $("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  set_data = (agreement_content) => {
    this.setState({
      final_content: agreement_content,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.value : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  preview = () => {
    if (!this.signaturePad.isEmpty()) {
      var data = this.signaturePad
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");
      $("#divSig").show();
      $("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  class_manage_link = (ids) => {
    if (ids === 0) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  };

  class_manage_content = (ids) => {
    if (ids === 0) {
      return "tab-pane active";
    } else {
      return "tab-pane";
    }
  };

  class_required = (ids) => {
    if (ids === 1) {
      return (
        <sup style={{ fontSize: "100%" }}>
          <small className="" style={{ color: "#e85347", fontSize: "100%" }}>
            *
          </small>
        </sup>
      );
    } else {
      return "";
    }
  };

  set_tabs = (ids) => {
    if (ids !== "") {
      return "#agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  set_tabs_content = (ids) => {
    if (ids !== "") {
      return "agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  show_agreement_content = async (ids, agreement_content) => {
    console.log("replacable_content: ", this.state.replacable_content);
    let final_content = agreement_content;
    if (final_content !== "") {
      final_content = REPLACECONTENT(
        agreement_content,
        this.state.replacable_content,
        ""
      ).replace(/\[\[IMG_VENDOR_SIGN\]\]/g, this.state.vendor_signature);
    }
    this.setState({
      final_content: final_content,
      agreement_id: ids,
    });
  };

  addMenuHistory = (link) => {
    this.props.history.push(link);
  };

  setMarkUp = (value, tableMeta) => {
    let pdf_path = tableMeta.rowData[2];
    console.log("pdf_path: ", pdf_path);
    if (tableMeta.rowData[1] === "Yes") {
      this.setState({
        pdfurl:
          process.env.REACT_APP_API_URL + "public/msa_agreements/" + pdf_path,
      });
    } else {
      this.setState({
        pdfurl: "",
      });
    }
    console.log(this.state.pdfurl);
    return this.state.pdfurl;
  };

  clear = () => {
    this.signaturePad.clear();
    $("#divImgSig").hide();
    $(".clsimgSig").attr("src", "");
    $(".clsimgSig").hide();
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    this.props.agreementSigned();
  };

  showModal = () => {
    window.$("#modalForm").modal("show");
  };

  downloadPdf = (value, tableMeta) => {
    fetch(process.env.REACT_APP_API_URL + "agreements/get_agreement_data")
      .then((x) => x.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "a.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Unsigned Agreements" />
                    </h3>
                  </div>
                </div>
              </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    {this.state.tableLoaderBlockImage === true ? (
                      tableLoaderBlockImage()
                    ) : (
                      <>
                        {this.state.agreementData.length > 0 ? (
                          <>
                            <div className="nk-block nk-block-lg">
                              <div
                                className="card card-bordered"
                                style={{
                                  backgroundColor: this.props.backgroundColor,
                                  border: "none",
                                }}
                              >
                                <div className="card-inner card-inner-xl">
                                  <ul className="nav nav-tabs">
                                    {this.state.agreementData.map(
                                      (agreements, i) => (
                                        <li className="nav-item">
                                          <a
                                            className={this.class_manage_link(
                                              i
                                            )}
                                            data-toggle="tab"
                                            href={this.set_tabs(agreements[6])}
                                            onClick={() =>
                                              this.show_agreement_content(
                                                agreements[0],
                                                agreements[1]
                                              )
                                            }
                                          >
                                            <em className="icon ni ni-list-round"></em>
                                            <span>
                                              {agreements[2]}{" "}
                                              {this.class_required(
                                                agreements[3]
                                              )}
                                            </span>
                                          </a>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                  <div className="tab-content">
                                    {this.state.agreementData.map(
                                      (agreementscontent, i) => (
                                        <div
                                          className={this.class_manage_content(
                                            i
                                          )}
                                          id={this.set_tabs_content(
                                            agreementscontent[6]
                                          )}
                                        >
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: REPLACECONTENT(
                                                agreementscontent[1],
                                                this.state.replacable_content,
                                                ""
                                              ).replace(
                                                /\[\[IMG_VENDOR_SIGN\]\]/g,
                                                this.state.vendor_signature
                                              ),
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                     {/* ACTION BUTTONS DIV  START*/}
                              {this.state.agreement_type === "esign" ? (
                                <div id="signature_div">
                                  <Row>
                                    <Col sm={12}>
                                      <div>
                                        <strong>Sign Here:</strong>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row style={{ marginTop: "1%" }}>
                                    <Col sm={12}>
                                      <SignatureCanvas
                                        clearOnResize={true}
                                        canvasProps={{
                                          height: "400px",
                                          className: styles.sigPad,
                                        }}
                                        velocityFilterWeight={0.7}
                                        throttle={50}
                                        ref={(ref) => {
                                          this.signaturePad = ref;
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row style={{ marginTop: "1%" }}>
                                    <Col sm={12}>
                                      <div style={{ float: "right" }}>
                                        <button
                                          className="btn btn-warning"
                                          style={{
                                            margin: "13px 12px 12px 10px",
                                          }}
                                          onClick={() => this.clear()}
                                        >
                                          Clear
                                        </button>
                                        <button
                                          className="btn btn-primary  "
                                          onClick={() => this.open_signee()}
                                          id="signee_button"
                                        >
                                          Send to Signee
                                        </button>
                                        <button
                                          className="btn btn-success"
                                          style={{
                                            margin: "13px 12px 12px 10px",
                                          }}
                                          onClick={() => this.preview()}
                                        >
                                          Preview Signature
                                        </button>
                                        <button
                                          className="btn"
                                          type="button"
                                          id="loading_btn"
                                          style={{
                                            display: "none",
                                            backgroundColor: THEME_COLOR,
                                            color: THEME_TEXT_COLOR,
                                          }}
                                          disabled
                                        >
                                          <span
                                            className="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                          <span> Wait... </span>
                                        </button>
                                        <button
                                          className="btn "
                                          type="button"
                                          id="loading_btn"
                                          style={{
                                            display: "none",
                                            backgroundColor: THEME_COLOR,
                                            color: THEME_TEXT_COLOR,
                                          }}
                                          disabled
                                        >
                                          <span
                                            className="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                          <span> Wait... </span>
                                        </button>
                                        <button
                                          className="btn"
                                          style={{
                                            backgroundColor: THEME_COLOR,
                                            color: THEME_TEXT_COLOR,
                                            margin: "13px 0px 12px 0px",
                                          }}
                                          onClick={() => this.save()}
                                          id="save_btn"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ) : null}
                              {this.state.agreement_type === "accept" ? (
                                <div
                                  id="signature_div"
                                  className="signature_width"
                                >
                                  <div className="nk-block">
                                    <div className="row g-gs">
                                      <div className="col-md-12">
                                        <div className="card card-bordered card-full">
                                          <div className="nk-wg1">
                                            <div className="nk-wg1-block">
                                              <div className="nk-wg1-text">
                                                <div className="form-group">
                                                  <div className="custom-control custom-control-xs custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      name="is_checked"
                                                      className="custom-control-input"
                                                      id="checkbox"
                                                      defaultChecked={
                                                        this.state.is_checked
                                                      }
                                                      value="yes"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor="checkbox"
                                                    >
                                                      I accept the{" "}
                                                      {
                                                        this.state
                                                          .agreement_title
                                                      }
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="nk-wg1-action"
                                              style={{ padding: "1.25rem" }}
                                            >
                                              <div className="form-group ">
                                                <button
                                                  className="btn btn-lg"
                                                  type="button"
                                                  id="loading_btn"
                                                  style={{
                                                    display: "none",
                                                    backgroundColor:
                                                      THEME_COLOR,
                                                    color: THEME_TEXT_COLOR,
                                                  }}
                                                  disabled
                                                >
                                                  <span
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                  <span> Wait... </span>
                                                </button>
                                                <button
                                                  className="btn btn-lg"
                                                  type="button"
                                                  id="loading_btn"
                                                  style={{
                                                    display: "none",
                                                    backgroundColor:
                                                      THEME_COLOR,
                                                    color: THEME_TEXT_COLOR,
                                                  }}
                                                  disabled
                                                >
                                                  <span
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                  <span> Wait... </span>
                                                </button>

                                                <button
                                                  className="btn btn-primary"
                                                  onClick={() => this.save()}
                                                  id="save_btn"
                                                >
                                                  Accept and Continue
                                                </button>
                                                <button
                                                  className="btn btn-warning"
                                                  onClick={() =>
                                                    this.open_signee()
                                                  }
                                                  style={{
                                                    margin: "0px 12px 0px 10px",
                                                  }}
                                                  id="signee_button"
                                                >
                                                  Send to Signee
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {/* ACTIONS BUTTONS DIV END */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="example-alert">
                              <div className="alert alert-pro alert-warning">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>You have no unsigned agreements.</p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {/* Table 5th Col End */}
                  {/* SUCCESS MODAL START */}
                  <div className="modal fade" tabIndex="-1" id="modalAlert">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHide();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">
                              {this.state.successMessage}
                            </h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                You’ve successfully signed{" "}
                                <strong>Agreement</strong>
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHide();
                                }}
                              >
                                OK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* SUCCESS MODAL End */}
                  {/* ERROR MODAL START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalAlertError"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                            <h4 className="nk-modal-title">
                              Unable to Process!
                            </h4>
                            <div className="nk-modal-text">
                              <p className="lead">{this.state.errorMessage}</p>
                            </div>
                            <div className="nk-modal-action mt-5">
                              <a
                                href="#"
                                className="btn btn-lg btn-mw btn-light"
                                data-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ERROR MODAL END */}
                  {/* Signee Model */}

              <div className="modal fade" tabindex="-1" id="modalSendSignee">
                <div
                  className="modal-dialog modal-dialog-top modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Send To Signee</h5>
                    </div>
                    <div className="modal-body">
                      {this.state.errorMessageSignee !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageSignee}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageSignee !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageSignee}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />

                      <div className="row g-4">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="form-group">
                              <MutextField
                                required
                                id="signee_name"
                                name="signee_name"
                                type="text"
                                label="Full Name"
                                defaultValue={this.state.signee_name}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <MutextField
                              required
                              id="signee_email"
                              name="signee_email"
                              type="text"
                              label="Email Address"
                              defaultValue={this.state.signee_email}
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <MutextField
                              id="signee_title"
                              name="signee_title"
                              type="text"
                              label="Title"
                              defaultValue={this.state.signee_title}
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div class="example-alert">
                            <div class="alert alert-warning alert-icon">
                              <em class="icon ni ni-alert-circle"></em>{" "}
                              <strong>NOTE</strong>. This will send new
                              agreement sign request. Your previous request will
                              be discarded if you have sent any.
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 text-right">
                          <button
                            type="button"
                            className="btn btn-light"
                            style={{ marginRight: "10px" }}
                            onClick={() => this.close_signee()}
                          >
                            <em class="icon ni ni-step-back"></em> Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.send_to_signee()}
                            disabled={this.state.disabled}
                          >
                            <em className="icon ni ni-done"></em> Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Signee Model */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
