import ContentLoader from "react-content-loader";
export const tableLoader = () => {
  return (
    <ContentLoader
      height={400}
      width={400}
      viewBox="0 0 400 500"
      speed={2}
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
    >
      <rect x="0" y="0" width="80" height="30" />
      <rect x="90" y="0" width="320" height="30" />

      <rect x="0" y="50" width="80" height="30" />
      <rect x="90" y="50" width="320" height="30" />

      <rect x="0" y="100" width="80" height="30" />
      <rect x="90" y="100" width="320" height="30" />

      <rect x="0" y="150" width="80" height="30" />
      <rect x="90" y="150" width="320" height="30" />

      <rect x="0" y="200" width="80" height="30" />
      <rect x="90" y="200" width="320" height="30" />

      <rect x="0" y="250" width="80" height="30" />
      <rect x="90" y="250" width="320" height="30" />

      <rect x="0" y="300" width="80" height="30" />
      <rect x="90" y="300" width="320" height="30" />

      <rect x="0" y="350" width="80" height="30" />
      <rect x="90" y="350" width="320" height="30" />
    </ContentLoader>
  );
};
