import React from "react";
import ContentLoader from "react-content-loader";
const newWidth = window.innerWidth * 1;
export const FormLoader = (props) => {
  return (
    <ContentLoader
      height={200}
      width={newWidth}
      viewBox={`0 0 ${newWidth} 200`}
      backgroundColor="#d9d9d9"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="3.75%" y="15" rx="4" ry="4" width="32.5%" height="10" />
      <rect x="38.75%" y="15" rx="3" ry="3" width="32.5%" height="10" />
      <rect x="73.75%" y="15" rx="3" ry="3" width="22.5%" height="10" />
      <rect x="3.75%" y="50" rx="3" ry="3" width="22.5%" height="10" />
      <rect x="28.75%" y="50" rx="3" ry="3" width="15%" height="10" />
      <rect x="46.25%" y="50" rx="3" ry="3" width="50%" height="10" />
      <rect x="3.75%" y="90" rx="3" ry="3" width="32.5%" height="10" />
      <rect x="40%" y="90" rx="3" ry="3" width="30%" height="10" />
      <rect x="72.5%" y="90" rx="3" ry="3" width="23.75%" height="10" />
      <rect x="3.75%" y="130" rx="3" ry="3" width="32.5%" height="10" />
      <rect x="40%" y="130" rx="3" ry="3" width="56.25%" height="10" />
    </ContentLoader>
  );
};
