import { React } from "react";
import { Component } from "react";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { connect } from "react-redux";
import { addSettings } from "../../../actions";
import { signedAgreements } from "./../../../config/client_api_calls";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

const auth = new HelperClass();

class Agreements extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
      pdfurl: "",
      unsigned_agreements: "1",
      columnServices: [
        { name: "Agreement" },
        { name: "Signee Name" },
        { name: "Signee Title" },
        { name: "Signee Email" },
        { name: "Date Signed" },
        {
          name: "Signed",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.signedText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Mandatory",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.mandatoryText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Download PDF",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewDownloadBtns(tableMeta)}</div>;
            },
          },
        },
      ],
    };
  }

  signedText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;
    if (is_default === "Yes") {
      return (
        <span className="badge badge-outline-secondary">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  mandatoryText = (value, tableMeta) => {
    let is_default = value;
    if (is_default === "No") {
      return (
        <span className="badge badge-outline-warning">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-secondary">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  viewDownloadBtns = (tableMeta) => {
    return (
      <div>
        <a
          onClick={() =>
            this.addMenuHistory(
              "/" +
                auth.getResellerIdentifier() +
                "/client/agreements/pdfdownload/" +
                btoa(tableMeta.rowData[0] + "###" + tableMeta.rowData[7])
            )
          }
          disabled={tableMeta.rowData[5] === "No" ? true : false}
          target="_blank"
          style={{ cursor: "pointer", color: "#006393" }}
        >
          <span className="badge badge-outline-primary">
            <em
              className="icon ni ni-download"
              style={{ fontSize: "18px" }}
            ></em>
          </span>
        </a>{" "}
      </div>
    );
  };

  async componentDidMount() {
    const servicesResponce = await signedAgreements(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("servicesResponce.data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  setMarkUp = (value, tableMeta) => {
    let pdf_path = tableMeta.rowData[2];
    console.log("pdf_path: ", pdf_path);
    if (tableMeta.rowData[1] === "Yes") {
      this.setState({
        pdfurl:
          process.env.REACT_APP_API_URL + "public/msa_agreements/" + pdf_path,
      });
    } else {
      this.setState({
        pdfurl: "",
      });
    }
    console.log(this.state.pdfurl);
    return this.state.pdfurl;
  };

  modalHide = () => {
    window.$("#modalForm").modal("hide");
  };

  showModal = () => {
    window.$("#modalForm").modal("show");
  };

  downloadPdf = (value, tableMeta) => {
    fetch(process.env.REACT_APP_API_URL + "agreements/get_agreement_data")
      .then((x) => x.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "a.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Signed Agreements"
                        icon="icon ni ni-file-docs"
                      />
                    </h3>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="Signed Agreements"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* DETAIL MODAL START */}
                  <div className="modal fade" tabIndex="-1" id="modalForm">
                    <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">Download PDF</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAIL MODAL End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
