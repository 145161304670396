import React from "react";
import ContentLoader from "react-content-loader";
const newWidth = window.innerWidth * 1;
console.log("ContentLoader newWidth: ", newWidth);
export const TableLoader = (props) => (
  <ContentLoader
    width={newWidth}
    height={550}
    viewBox={`0 0 ${newWidth} 550`}
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
    {...props}
  >
    <rect x="5.1%" y="45" rx="3" ry="3" width="90.6%" height="17" />
    <circle cx="87.9%" cy="123" r="11" />
    <circle cx="91.4%" cy="123" r="11" />
    <rect x="10.4%" y="115" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="30.5%" y="114" rx="3" ry="3" width="29.9%" height="15" />
    <rect x="66.1%" y="114" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="5.5%" y="155" rx="3" ry="3" width="89.7%" height="2" />
    <circle cx="88.0%" cy="184" r="11" />
    <circle cx="91.5%" cy="184" r="11" />
    <rect x="10.5%" y="176" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="30.6%" y="175" rx="3" ry="3" width="29.9%" height="15" />
    <rect x="66.2%" y="175" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="5.6%" y="216" rx="3" ry="3" width="89.7%" height="2" />
    <circle cx="88.1%" cy="242" r="11" />
    <circle cx="91.6%" cy="242" r="11" />
    <rect x="10.6%" y="234" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="30.7%" y="233" rx="3" ry="3" width="29.9%" height="15" />
    <rect x="66.3%" y="233" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="5.7%" y="274" rx="3" ry="3" width="89.7%" height="2" />
    <circle cx="88.2%" cy="303" r="11" />
    <circle cx="91.7%" cy="303" r="11" />
    <rect x="10.7%" y="295" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="30.8%" y="294" rx="3" ry="3" width="29.9%" height="15" />
    <rect x="66.4%" y="294" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="5.8%" y="335" rx="3" ry="3" width="89.7%" height="2" />
    <circle cx="88.1%" cy="363" r="11" />
    <circle cx="91.6%" cy="363" r="11" />
    <rect x="10.6%" y="355" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="30.7%" y="354" rx="3" ry="3" width="29.9%" height="15" />
    <rect x="66.3%" y="354" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="5.7%" y="395" rx="3" ry="3" width="89.7%" height="2" />
    <circle cx="88.2%" cy="424" r="11" />
    <circle cx="91.7%" cy="424" r="11" />
    <rect x="10.7%" y="416" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="30.8%" y="415" rx="3" ry="3" width="29.9%" height="15" />
    <rect x="66.4%" y="415" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="5.5%" y="453" rx="3" ry="3" width="89.7%" height="2" />
    <rect x="5.1%" y="49" rx="3" ry="3" width=".2%" height="465" />
    <rect x="95.5%" y="49" rx="3" ry="3" width=".2%" height="465" />
    <circle cx="88.2%" cy="484" r="11" />
    <circle cx="91.7%" cy="484" r="11" />
    <rect x="10.7%" y="476" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="30.8%" y="475" rx="3" ry="3" width="29.9%" height="15" />
    <rect x="66.4%" y="475" rx="3" ry="3" width="14.1%" height="15" />
    <rect x="5.5%" y="513" rx="3" ry="3" width="89.7%" height="2" />
    <rect x="5.2%" y="80" rx="3" ry="3" width="90.6%" height="17" />
    <rect x="5.3%" y="57" rx="3" ry="3" width="6.8%" height="33" />
    <rect x="22.2%" y="54" rx="3" ry="3" width="14.9%" height="33" />
    <rect x="54.4%" y="55" rx="3" ry="3" width="13.7%" height="33" />
    <rect x="78.2%" y="56" rx="3" ry="3" width="7.2%" height="33" />
    <rect x="93.3%" y="54" rx="3" ry="3" width="2.4%" height="33" />
  </ContentLoader>
);
